import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import { ToastContainer } from 'react-toastify'
import { Route, Routes } from 'react-router-dom'
import CustomerFormView from './views/customerForm/CustomerFormView'
import HomeView from './views/HomeView'
import NotFoundView from './views/NotFoundView'

function App (): JSX.Element {
  return (
    <div className="App">
      <ToastContainer theme="light" progressStyle={{ background: '#5c6d97' }} position="bottom-right" />
      <Routes>
        <Route path="/f/:token" element={<CustomerFormView />} />

        <Route path="/" element={<HomeView />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </div>
  )
}

export default App

import React from 'react'
import { ICustomerInputDocument } from '../../models/customerForm/ICustomerInputDocument'
import { IOrderInformation } from '../../models/customerForm/IOrderInformation'
import { IServiceGroupFieldValue } from '../../models/customerForm/IServiceGroupFieldValue'
import FormControl from './FormControl'

interface Props {
  orderInformation: IOrderInformation
  document: ICustomerInputDocument
  serviceGroupFieldValues: IServiceGroupFieldValue[]
  onUpdateOrderInformationFormControl: (field: string, value: string, valid: boolean) => void
  onUpdateServiceGroupFieldFormControl: (ServiceGroupFieldId: number, value: string, valid: boolean) => void
}

function FormControls (props: Props): JSX.Element {
  const {
    orderInformation,
    serviceGroupFieldValues,
    document,
    onUpdateOrderInformationFormControl,
    onUpdateServiceGroupFieldFormControl
  } = props

  return (
    <>
      {
        document.FormControls.map((formControl, index) => (
          <FormControl
            key={index}
            index={index}
            orderInformation={orderInformation}
            serviceGroupFieldValues={serviceGroupFieldValues}
            formControl={formControl}
            onUpdateOrderInformationFormControl={onUpdateOrderInformationFormControl}
            onUpdateServiceGroupFieldFormControl={onUpdateServiceGroupFieldFormControl}
          />
        ))
      }
    </>
  )
}

export default FormControls

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../components/common/header/Header'

const NotFoundView = (): JSX.Element => {
  return (<>
    <Header/>
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center">
          <p>
            Not Found.
          </p>
        </Col>
      </Row>

    </Container>

  </>
  )
}

export default NotFoundView

import axios from 'axios'
import { IGetCustomerFormRequest } from '../models/customerForm/interface/IGetCustomerFormRequest'

interface ICustomerFormTokenValidation {
  valid: boolean
}

interface ICustomerFormSubmitResponse {
  status: string
}

export const validateCustomerInputFormToken = async (token: string): Promise<ICustomerFormTokenValidation> => {
  return await axios({
    method: 'POST',
    url: '/api/customerform/token/validate',
    data: { token }
  }
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    } else { throw new Error(`HTTPStatusCode: ${response.status}. Message: ${response.statusText}`) }
  })
}

export const getCustomerInputForm = async (Token: string): Promise<IGetCustomerFormRequest> => {
  const response = await axios.get('/api/token/')
  axios.defaults.headers.post['X-CSRF-Token'] = response.data.CSRFToken

  return await axios({
    method: 'POST',
    url: '/api/customerform',
    data: { Token }
  }
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(`HTTPStatusCode: ${response.status}. Message: ${response.statusText}`)
    }
  })
}

export const saveCustomerInputForm = async (
  Token: string,
  OrderId: string,
  FormId: string,
  Parameters: Array<{ Name: string, Data: string | Number | boolean | null }>,
  ServiceGroupFieldValues: Array<{ ServiceGroupFieldValueID: number, Data: String }>
): Promise<ICustomerFormSubmitResponse> => {
  return await axios({
    method: 'POST',
    url: '/api/customerform/save',
    data: { Token, OrderId, FormId, Parameters, ServiceGroupFieldValues }
  }
  ).then((response) => {
    if (response.status === 200) {
      return response.data
    } else { throw new Error(`HTTPStatusCode: ${response.status}. Message: ${response.statusText}`) }
  })
}

export const getTmwSelectOptions = async (
  Token: string,
  OrderId: string,
  SelectProfile: String
): Promise<Array<{ Value: string, Name: string }>> => {
  return await axios({
    method: 'POST',
    url: '/api/customerform/selectoptions',
    data: { Token, OrderId, SelectProfile }
  }).then((response) => {
    if (response.status === 200) {
      return response.data
    } else { throw new Error(`HTTPStatusCode: ${response.status}. Message: ${response.statusText}`) }
  })
}

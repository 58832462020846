import React from 'react'
import './CustomerForm.css'
import { Container } from 'react-bootstrap'
import Header from '../../components/common/header/Header'
import CustomerInputForm from '../../components/customerInput/CustomerInputForm'

const CustomerFormView = (): JSX.Element => {
  return (
    <>
      <Header />
      <Container fluid>
        <CustomerInputForm />
      </Container>
    </>
  )
}

export default CustomerFormView

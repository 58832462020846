import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { Col, Row, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import FormControls from './FormControls'
import useCustomerInputForm from './useCustomerInputForm'
import SimpleSpinner from '../common/SimpleSpinner'
import img_404 from '../../resources/img/404.webp'
import { saveCustomerInputForm } from '../../utils/requestHandler'

interface IOrderInformationFormDetail {
  Name: string
  Data: string | Number | boolean | null
  Valid: boolean
}

interface IServiceGroupFieldFormDetail {
  ServiceGroupFieldId: number
  ServiceGroupFieldValueID: number
  Data: string
  Valid: boolean
}

interface IOrderInformationForm {
  Parameters: IOrderInformationFormDetail[]
}

interface IServiceGroupFieldForm {
  ServiceGroupFieldValues: IServiceGroupFieldFormDetail[]
}

function CustomerInputForm (): JSX.Element {
  const params = useParams()
  // const [orderId, setOrderId] = useState<string>('')
  // const [orderInformationId, setOrderInformationId] = useState<string>('')
  const { loading, loaded, orderInformation, document, template, error, token, formId, serviceGroupFieldValues, orderId } = useCustomerInputForm(params.token)
  const [orderInformationForm, setOrderInformationForm] = useState<IOrderInformationForm | null>(null)
  const [serviceGroupFieldForm, setServiceGroupFieldForm] = useState<IServiceGroupFieldForm | null>(null)

  const [saving, setSaving] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)

  useEffect(() => {
    if (orderInformation == null) { return }

    const oif: IOrderInformationForm = { Parameters: [] }

    Object.keys(orderInformation).forEach(key => {
      // @ts-expect-error
      oif.Parameters.push({ Name: key, Data: orderInformation[key], Valid: true })
    })

    setOrderInformationForm({ ...oif })
  }, [orderInformation])

  useEffect(() => {
    if (document === null) { return }
    if (orderInformation == null) { return }

    const sgf: IServiceGroupFieldForm = { ServiceGroupFieldValues: [] }

    document?.FormControls.forEach(fc => {
      if (fc.DataType === 'DYNAMIC_FIELD' && fc.ServiceGroupFieldId != null) {
        const target = serviceGroupFieldValues.find(sgfv => sgfv.ServiceGroupFieldID === fc.ServiceGroupFieldId)
        if (target != null) {
          sgf.ServiceGroupFieldValues.push({
            ServiceGroupFieldId: fc.ServiceGroupFieldId,
            ServiceGroupFieldValueID: target.ServiceGroupFieldValueID,
            Data: target.Value,
            Valid: true
          })
        }
      }
    })
    setServiceGroupFieldForm({ ...sgf })
  }, [document, serviceGroupFieldValues])

  const save = (): void => {
    if (orderInformationForm != null && formId != null && serviceGroupFieldForm != null && orderId != null) {
      const p = orderInformationForm.Parameters.map(p => ({ Name: p.Name, Data: p.Data }))

      const s = serviceGroupFieldForm.ServiceGroupFieldValues.map(p => ({
        ServiceGroupFieldValueID: p.ServiceGroupFieldValueID,
        ServiceGroupFieldID: p.ServiceGroupFieldId,
        Data: p.Data
      }))

      setSaving(true)
      saveCustomerInputForm(token, String(orderId), formId, p, s)
        .then(data => {
          if (data.status === 'success') {
            setSaved(true)
            setSaving(false)
          } else {
            setSaved(false)
            setSaving(false)
          }
        })
        .catch(err => {
          console.log(err)
          setSaved(true)
          setSaving(false)
        })
    }
  }

  const updateOrderInformationForm = (field: string, value: string, valid: boolean): void => {
    if (orderInformationForm != null) {
      const resource = { ...orderInformationForm }
      resource.Parameters = orderInformationForm.Parameters.map(f => f.Name === field ? { ...f, Data: value, Valid: valid } : f)
      setOrderInformationForm({ ...resource })
    }
  }

  const updateServiceGroupFieldForm = (ServiceGroupFieldId: number, value: string, valid: boolean): void => {
    if (serviceGroupFieldForm != null) {
      const resource = { ...serviceGroupFieldForm }
      resource.ServiceGroupFieldValues = serviceGroupFieldForm.ServiceGroupFieldValues.map(f => f.ServiceGroupFieldId === ServiceGroupFieldId ? { ...f, Data: value, Valid: valid } : f)
      setServiceGroupFieldForm({ ...resource })
    }
  }

  useEffect(() => {
    if ((orderInformationForm?.Parameters.find(p => !p.Valid) != null) ||
       (serviceGroupFieldForm?.ServiceGroupFieldValues.find(p => !p.Valid) != null)
    ) {
      setDisableSubmitButton(true)
    } else {
      setDisableSubmitButton(false)
    }
  }, [orderInformationForm, serviceGroupFieldForm])

  return (
    <>
      <Row className='m-3'>
        <Col>
            {(loading || saving) && <SimpleSpinner/> }
        </Col>
      </Row>

      {
        (loaded && saved)
          ? <>
              <Row className='mt-5'>
              <Col
                  xs={{ span: 12, offset: 0 }}
              >
               <h5 className="text-center">
                  {
                    document?.General?.SubmitFormSuccessMessage ??
                    'Tack, dina uppgifter är nu uppdaterade och din order kommer fortsätta hanteras av kundtjänst.'
                  }
                </h5>
              </Col>
            </Row>
            </>
          : null
      }

        {
          (loaded && !saved && orderInformation != null && document != null && template != null)
            ? <>
              <Row className='mt-5'>
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
                className="mb-3">

                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(template) }}></div>

                <FormControls
                  orderInformation={orderInformation}
                  serviceGroupFieldValues={serviceGroupFieldValues}
                  document={document}
                  onUpdateOrderInformationFormControl={updateOrderInformationForm}
                  onUpdateServiceGroupFieldFormControl={updateServiceGroupFieldForm}
                />
                {
                  (!saving)
                    ? <Button
                  onClick={save}
                  disabled={disableSubmitButton}
                  >
                  {
                    document.General?.SubmitButtonTitle ?? 'Uppdatera'
                  }
                  </Button>
                    : null
                }
              </Col>
              </Row>

            </>
            : null
        }

      {
        (error === 403)
          ? <>
            <Row className='mt-5'>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 4, offset: 4 }}
                lg={{ span: 4, offset: 4 }}
              >
              <p className='text-center'>Oops..</p>
              <p className='text-center'>Något stämmer inte, har du använt rätt länk och ordernummer?</p>
              </Col>
            </Row>
          </>
          : null
      }

        {
        (error === 404)
          ? <>
            <Row className='mt-5'>
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 4, offset: 4 }}
                lg={{ span: 4, offset: 4 }}
              >
              <p className='text-center'>Oops. verkar som du redan använt länken och uppdaterat dina uppgifter?</p>
              <img className='img-fluid' src={img_404} alt="404 bild för en sida som inte existerar"/>
              </Col>
            </Row>
          </>
          : null
      }
    </>
  )
}

export default CustomerInputForm

import dayjs from 'dayjs'

interface IDateHelper {
  calculateEaster: (year: number) => Date
  isHoliday: (date: Date) => boolean
  isWeekend: (date: Date) => boolean
  getFutureDate: (minimumWorkingDays: number, overflowHour: number) => Date
}

export const DateHelper: IDateHelper = {
  calculateEaster: function (year: number): Date {
    const C = Math.floor(year / 100)
    const N = year - 19 * Math.floor(year / 19)
    const K = Math.floor((C - 17) / 25)
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15
    I = I - 30 * Math.floor((I / 30))
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11))
    let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)
    J = J - 7 * Math.floor(J / 7)
    const L = I - J
    const M = 3 + Math.floor((L + 40) / 44)
    const D = L + 28 - 31 * Math.floor(M / 4)

    return new Date(year, M - 1, D)
  },

  isHoliday: function (date: Date): boolean {
    const m = dayjs(date).month()
    const d = dayjs(date).date()
    const dd = dayjs(date).day()

    if (m === 11 && d === 24) { return true } // julafton
    if (m === 11 && d === 25) { return true } // juldagen
    if (m === 11 && d === 26) { return true } // annandag jul
    if (m === 11 && d === 31) { return true } // nyårsafton
    if (m === 0 && d === 1) { return true } // nyårsdagen
    if (m === 0 && d === 6) { return true } // trettondagen
    if (m === 4 && d === 1) { return true } // may first
    if (m === 5 && d === 6) { return true } // national day

    const easter = this.calculateEaster(dayjs(date).year())
    const longFriday = dayjs(easter).subtract(2, 'days') // långfredag
    const easterEvening = dayjs(easter).subtract(1, 'days') // påskafton
    const easterPassed = dayjs(easter).add(1, 'days') // annandagpåsk
    const christAscentionDay = dayjs(easter).add(39, 'days') // Kristi Himmelfärdsdag
    const pingstDay = dayjs(easter).add(49, 'days') // Pingstdagen

    if (dayjs(date).isSame(easter, 'day')) { return true }
    if (dayjs(date).isSame(longFriday, 'day')) { return true }
    if (dayjs(date).isSame(easterEvening, 'day')) { return true }
    if (dayjs(date).isSame(easterPassed, 'day')) { return true }
    if (dayjs(date).isSame(christAscentionDay, 'day')) { return true }
    if (dayjs(date).isSame(pingstDay, 'day')) { return true }

    if (m === 5 && d >= 20 && d <= 26 && dd === 6) { return true } // midsummer.

    if (((m === 9 && d === 31) || (m === 10 && d <= 6)) && dd === 6) { return true } // all saints
    return false
  },
  isWeekend: function (date: Date): boolean {
    if ([6, 0].includes(dayjs(date).day())) { return true }
    return false
  },
  getFutureDate: function (minimumWorkingDays: number = 0, overflowHour: number | undefined) {
    const d = new Date()
    let dd = dayjs(d)
    let i = 0

    if (overflowHour != null) {
      if (dd.hour() > overflowHour) {
        dd = dd.add(1, 'day')
      }
    }

    while (i < minimumWorkingDays) {
      dd = dayjs(dd).add(1, 'day')
      if (this.isWeekend(dd.toDate()) || this.isHoliday(dd.toDate())) {
        continue
      } else {
        i += 1
      }
    }
    return dd.toDate()
  }

}

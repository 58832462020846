import React, { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form } from 'react-bootstrap'
import { ICustomerInputDocumentFormControl } from '../../models/customerForm/ICustomerInputDocument'
import { IServiceGroupFieldValue } from '../../models/customerForm/IServiceGroupFieldValue'
import { DateHelper } from '../../utils/dateHelper'
import { getTmwSelectOptions } from '../../utils/requestHandler'

interface Props {
  index: number
  orderInformation: any
  serviceGroupFieldValues: IServiceGroupFieldValue[]
  formControl: ICustomerInputDocumentFormControl
  onUpdateOrderInformationFormControl: (field: string, value: string, valid: boolean) => void
  onUpdateServiceGroupFieldFormControl: (ServiceGroupFieldId: number, value: string, valid: boolean) => void
}

const getFormControlValueLogic = (formControl: ICustomerInputDocumentFormControl, orderInformation: any, serviceGroupFieldValues: IServiceGroupFieldValue[]): string => {
  if (formControl.DataType === 'ORDERINFORMATION') {
    if (formControl.ControlType === 'STRING' || formControl.ControlType === 'SELECT' || formControl.ControlType === 'TMW_SELECT') {
      const val = (orderInformation !== null) ? orderInformation[formControl.ParameterName] : ''
      return val
    }

    if (formControl.ControlType === 'DATETIME') {
      const val = (orderInformation !== null) ? orderInformation[formControl.ParameterName] : `${new Date().toLocaleDateString()}T00:00`
      return val
    }
  }

  if (formControl.DataType === 'DYNAMIC_FIELD' && serviceGroupFieldValues.length > 0) {
    if (formControl.ControlType === 'STRING' || formControl.ControlType === 'SELECT' || formControl.ControlType === 'TMW_SELECT') {
      const val = serviceGroupFieldValues.find(r => r.ServiceGroupFieldID === formControl.ServiceGroupFieldId)?.Value ?? ''
      return val
    }

    if (formControl.ControlType === 'DATETIME') {
      const val = serviceGroupFieldValues.find(r => r.ServiceGroupFieldID === formControl.ServiceGroupFieldId)?.Value ?? `${new Date().toLocaleDateString()}T00:00`
      return val
    }
  }

  return ''
}

function FormControl (props: Props): JSX.Element {
  const {
    index,
    orderInformation,
    serviceGroupFieldValues,
    formControl,
    onUpdateOrderInformationFormControl,
    onUpdateServiceGroupFieldFormControl
  } = props

  const [value, setValue] = useState<string>('')
  const [valid, setValid] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const [tmwSelectOptions, setTmwSelectOptions] = useState<Array<{ Value: string, Name: string }>>([])
  const [tmwSelectOptionsLoaded, setTmwSelectOptionsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const val = getFormControlValueLogic(formControl, orderInformation, serviceGroupFieldValues)
    setValue(val)
  }, [orderInformation])

  useEffect(() => {
    validate(value)
  }, [value])

  const validate = (value: string): void => {
    setValid(true)
    formControl.RegexValidations.forEach(regex => {
      const re = new RegExp(regex.Regex)
      const v: boolean = re.test(value)

      if (!v && regex.Match) {
        setValid(false)
        setErrorMessage(regex.ErrorMessage ?? 'Oops, vänligen kontrollera...')
      }

      if (v && !regex.Match) {
        setValid(false)
        setErrorMessage(regex.ErrorMessage ?? 'Oops, vänligen kontrollera...')
      }
    })

    if (['SELECT', 'TMW_SELECT'].includes(formControl.ControlType) && value === 'NULL') {
      setValid(false)
      setErrorMessage('Vänligen välj i rullgardinsmenyn.')
    }

    if (formControl.ControlType === 'DATETIME') {
      if (formControl.DateConfig == null) {
        setValid(true)
        return
      }

      if (formControl.DateConfig.DisableHolidays && DateHelper.isHoliday(new Date(value))) {
        setValid(false)
        setErrorMessage('Välj en dag som inte är en helgdag')
        return
      }

      if (formControl.DateConfig.DisableWeekends && DateHelper.isWeekend(new Date(value))) {
        setValid(false)
        setErrorMessage('Välj en dag som inte är en på en helg')
        return
      }

      setValid(true)
      setErrorMessage('')
    }
  }

  const onUpdate = (newValue: string): void => {
    validate(newValue)
    setValue(newValue)
  }

  useEffect(() => {
    if (formControl.DataType === 'ORDERINFORMATION') {
      if (formControl.ControlType === 'DATETIME') {
        // expected format to save datetime is '2022-12-01 23:56'
        onUpdateOrderInformationFormControl(formControl.ParameterName, value.replace('T', ' '), valid)
      } else {
        onUpdateOrderInformationFormControl(formControl.ParameterName, value, valid)
      }
    }

    if (formControl.DataType === 'DYNAMIC_FIELD' &&
      formControl.ServiceGroupFieldId != null) {
      onUpdateServiceGroupFieldFormControl(
        formControl.ServiceGroupFieldId,
        value,
        valid
      )
    }
  }, [value, valid])

  useEffect(() => {
    const controller = new AbortController()
    if (tmwSelectOptionsLoaded) { return }

    if (formControl.ControlType === 'TMW_SELECT') {
      getTmwSelectOptions('Token', 'OrderId', formControl.SelectConfig.SelectProfile)
        .then(data => {
          setTmwSelectOptions(data)
          setTmwSelectOptionsLoaded(true)
        })
        .catch(err => { console.log(err) })
    }

    return () => { controller.abort() }
  }, [])

  const getMinDefaultDate = (): string => {
    if (formControl.DateConfig?.DefaultTime != null) {
      return `${DateHelper.getFutureDate(formControl.DateConfig?.MinimumWorkingDays ?? 0, 12).toLocaleDateString()}T${formControl.DateConfig.DefaultTime}`
    }
    return `${DateHelper.getFutureDate(formControl.DateConfig?.MinimumWorkingDays ?? 0, 12).toLocaleDateString()}T00:00`
  }

  return (
    <>
      {
        <Col>
        <>
        <FloatingLabel
          controlId={`${formControl.Label}-${index}`}
          label={formControl.Label}
          className="mb-3"
        >
          {
            (formControl.ControlType === 'STRING')
              ? <>
                  <Form.Control
                    type="text"
                    placeholder=''
                    aria-describedby={`helpblock-${index}`}
                    size="sm"
                    value={value}
                    onChange={(e) => { onUpdate(e.target.value) }}
                    isInvalid={!valid}
                  />
                </>
              : null
          }

          {
            (formControl.ControlType === 'DATETIME')
              ? <>
                  <Form.Control
                    type="datetime-local"
                    placeholder={formControl.Placeholder}
                    aria-describedby={`helpblock-${index}`}
                    min={getMinDefaultDate()}
                    // max="2023-06-17T00:00"
                    value={value}
                    onChange={(e) => {
                      if (formControl.DateConfig?.DefaultTime != null) {
                        const transformed = e.target.value.split('T')[0] + 'T' + formControl.DateConfig.DefaultTime
                        onUpdate(transformed)
                      } else {
                        onUpdate(e.target.value)
                      }
                    }}
                    isInvalid={!valid}
                  />
              </>
              : null
          }

          {
            (formControl.ControlType === 'SELECT' && formControl.SelectConfig.SelectOptions.length > 0)
              ? <>
                  <Form.Select
                    size="sm"
                    aria-describedby={`helpblock-${index}`}
                    value={formControl.SelectConfig.SelectOptions.find(opt => String(opt.Value) === String(value))?.Value ?? 'NULL'}
                    onChange={(e) => {
                      onUpdate(e.target.value)
                    } }
                    isInvalid={!valid}
                  >
                    <option value='NULL'>{formControl.Placeholder}</option>
                    {
                      formControl.SelectConfig.SelectOptions.map((opt, index) => (<option key={index} value={opt.Value}>{opt.Name}</option>))
                    }
                  </Form.Select>
              </>
              : null
          }

          {
            (formControl.ControlType === 'TMW_SELECT' && tmwSelectOptionsLoaded && Array.isArray(tmwSelectOptions))
              ? <>
                  <Form.Select
                    size="sm"
                    aria-describedby={`helpblock-${index}`}
                    value={tmwSelectOptions.find(opt => String(opt.Value) === String(value))?.Value ?? 'NULL'}
                    onChange={(e) => {
                      onUpdate(e.target.value)
                    }}
                    isInvalid={!valid}
                  >
                    <option value='NULL'>{formControl.Placeholder}</option>
                    {
                      tmwSelectOptions.map((opt, index) => (<option key={index} value={opt.Value}>{opt.Name}</option>))
                    }
                  </Form.Select>
                </>
              : null
          }

          {
            (valid)
              ? <>
                {/* loading select profiles take a moment */}
                { ((formControl.ControlType !== 'TMW_SELECT') || ((formControl.ControlType === 'TMW_SELECT') && tmwSelectOptionsLoaded))
                  ? <>
                    <Form.Text id={`helpblock-${index}`} muted>
                      {formControl.Description}
                    </Form.Text>
                  </>
                  : null
                }
              </>
              : <>
              <Form.Control.Feedback type="invalid">
                {errorMessage}
              </Form.Control.Feedback>
              </>
        }
        </FloatingLabel>
        </>
        </Col>
      }
    </>
  )
}

export default FormControl

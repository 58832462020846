import React from 'react'
import { Row, Spinner } from 'react-bootstrap'

interface Props {
  showLabel?: boolean
}

export default function SimpleSpinner (props: Props): JSX.Element {
  const { showLabel } = props

  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
          <Spinner animation="border" role="status">
          </Spinner>
      </Row>
      {
      (showLabel === true) &&
      <Row
        className={'mt-1'}
        style={{ justifyContent: 'center' }}
        >
        Laddar...
      </Row>
      }
    </>
  )
}

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../components/common/header/Header'

const HomeView = (): JSX.Element => {
  return (
    <>
      <Header/>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <p>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HomeView

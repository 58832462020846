import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { ICustomerInputDocument } from '../../models/customerForm/ICustomerInputDocument'
import { IOrderInformation } from '../../models/customerForm/IOrderInformation'
import { IServiceGroupFieldValue } from '../../models/customerForm/IServiceGroupFieldValue'
import { getCustomerInputForm } from '../../utils/requestHandler'

interface IUseCustomerInputForm {
  loading: boolean
  loaded: boolean
  orderInformation: IOrderInformation | null
  document: ICustomerInputDocument | null
  template: string | null
  error: number | null
  token: string
  formId: string | null
  serviceGroupFieldValues: IServiceGroupFieldValue[]
  orderId: number | null
}

export default function useCustomerInputForm (
  Token: string | null | undefined
): IUseCustomerInputForm {
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')
  const [orderId, setOrderId] = useState<number | null>(null)
  const [document, setDocument] = useState<ICustomerInputDocument | null>(null)
  const [orderInformation, setOrderInformation] = useState<IOrderInformation | null>(null)
  const [formId, setFormId] = useState<string | null>(null)
  const [template, setTemplate] = useState<string | null>(null)
  const [error, setError] = useState<number | null>(null)
  const [serviceGroupFieldValues, setServiceGroupFieldValues] = useState<IServiceGroupFieldValue[]>([])

  useEffect(() => {
    if (loaded) return

    if (Token == null) {
      setDocument(null)
      setOrderInformation(null)
      setServiceGroupFieldValues([])
      return
    }

    setLoading(true)
    getCustomerInputForm(Token).then((data) => {
      setOrderId(data.OrderId)
      setOrderInformation(data.OrderInformation)
      setDocument(data.Document)
      setTemplate(data.Template)
      setFormId(data.FormId)
      setToken(Token)
      setServiceGroupFieldValues(data.ServiceGroupFieldValues)
      setLoaded(true)
      setError(null)
    }).catch((err: AxiosError) => {
      setError(err.request.status)
      setLoaded(false)
    }).finally(() => { setLoading(false) })
  }, [Token])

  return { loading, loaded, orderInformation, document, template, error, token, formId, serviceGroupFieldValues, orderId }
}
